'use client'

export default function Error({error, reset}: {
    error: Error & { digest?: string }
    reset: () => void
}) {
    return <div style={{
        width: '100%',
        padding: '10rem 0',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "1rem"
    }}>
        {error.name}
        {error.message}
        {error.digest}
    </div>
}